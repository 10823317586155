<template>
    <div class="cus-dash-menu">
        <ul>
            <li><router-link :to="{ name: 'customer_dashboard' }">Dashboard</router-link></li>
            <li><router-link :to="{ name: 'customer_order' }">Your Order</router-link></li>
            <li><router-link :to="{ name: 'order_from_history' }">Order from history</router-link></li>
            <!--<li><router-link  :to="{ name: 'my_wish_list' }">My Wishlist</router-link></li>-->
            <li @click="confirmDisableId" style="cursor: pointer"><a>Diactive Account</a></li>
            <li @click="logout" style="cursor: pointer"><a>Logout</a></li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["shoppingBag", "total", "accessToken", "customer"]),
    },
    methods: {
        logout() {
            this.$store.dispatch("logout");
            this.$router.push({ name: 'home' }).catch(() => { });
            location.reload();
        },
        confirmDisableId() {
            if (confirm("Are you sure you want to disable your account? This action cannot be undone.")) {
                this.disableId();
            }
        },
        async disableId() {
            try {
                // Call API to disable the customer account
                await this.$store.dispatch("disableId", { id: 12 });
                //alert("Your account has been disabled.");

                // Perform logout after disabling account
                //this.logout();
            } catch (error) {
                console.error("Error disabling account:", error);
                alert("An error occurred while disabling your account. Please try again.");
            }
        }
    }
};
</script>

<style>
.cus-dash-menu {
    background: #000;
    margin-bottom: 5px;
}

.cus-dash-menu ul li a {
    color: #fff;
    display: block;
    padding: 15px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 13px;
    border-bottom: 1px solid #560000;
    text-align: left;
}

.cus-dash-menu ul li a:hover {
    color: red;
}

.cus-dash-menu ul li a.router-link-active {
    color: red;
}
</style>