<template>
  <div class="row">
    <div class="col-md-12">
      <!--          <pre>{{productDetails.product_images.filter(img => img.web_id === 2)[0]}}/{{inImage}}</pre>-->
      <div class="thumbnail-img col-md-2">
        <ul>
          <li class="thumb-img" v-for="image in singleProductImg === null
            ? productDetails.product_images.filter((img) => img.web_id === 2)
            : singleProductImg.filter((img) => img.web_id === 2)">
            <img @click="asignImage(image.large_image)"
              :src="'https://eurasiasupplies.com/euadmin/' + image.large_image" alt="" />
          </li>
        </ul>
      </div>
      <div class="col-md-4 wrap-border">
        <div class="immage-zoomer">
          <inner-image-zoom class="large-img-sz" :src="'https://eurasiasupplies.com/euadmin/' +
            `${inImage == null
              ? productDetails.product_images.filter((img) => img.web_id === 2)[0]
                .large_image
              : inImage
            }`
            " :zoomScale="1" zoomType="hover" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <div class="product-details">
              <div class="product-desc">
                <!-- <h4>{{ productName }}</h4> -->
                <h1 style="
                    font-size: 1.4em;
                    font-weight: 700;
                    color: #252525;
                    text-align: start;
                    margin-bottom: 5px;
                  ">
                  {{ productName }}
                </h1>
                <p>
                  by
                  <b class="brand-name">
                    <router-link :to="{ name: 'brand', params: { slug: productDetails.brand.slug } }">
                      {{ productDetails.brand.name }}
                    </router-link>
                  </b>
                </p>
                <p v-if="origin">
                  <b>Manufacturer Country: {{ origin }}</b>
                </p>
                <p v-if="sku" class="sku">
                  <b>SKU : {{ sku }}</b>
                </p>
              </div>

              <div class="price">
                <b><span class="price-tag">
                    <h5 v-if="showPriceRange">
                      <span>Sale :</span> {{ productDetails.min_price }}৳ -
                      {{ productDetails.max_price }}৳
                    </h5>
                    <h5 v-if="!showPriceRange">
                      <span v-if="singleProct.product_price[0].offer_price > 0"><span
                          style="font-size: 17px; font-weight: 500">Price :</span>
                        {{ singleProct.product_price[0].offer_price }}</span>
                      <span v-else><span style="font-size: 17px; font-weight: 500">Price :</span>
                        {{ singleProct.product_price[0].price }}</span>
                      TK
                    </h5>
                    <!--{{ productDetails.product_price[0].offer_price?productDetails.product_price[0].offer_price: productDetails.product_price[0].price}}
              <del>{{ productDetails.product_price[0].offer_price > 0?productDetails.product_price[0].price > 0?productDetails.product_price[0].price:'':'' }}</del>৳-->
                  </span>
                </b>
                <p class="dsc">{{ productDetails.name }}</p>
              </div>

              <div class="product-color">
                <div class="color-name">
                  <p><b>Color:</b></p>
                </div>
                <div class="color-shade clearfix">
                  <ul>
                    <li v-for="color in productDetails.groupProducts.filter((x) =>
                      x.product.hasOwnProperty('product_color')
                    )" :key="color.id">
                      <img :class="selectedClrId === color.id ? 'activeImg' : ''"
                        v-if="color.product.product_color !== null" @click="productByColor(color, color.id)"
                        style="height: 25px; width: 50px; border-radius: 3px" :src="'https://eurasiasupplies.com/euadmin/' +
                          color.product.product_color.color_image
                          " alt="" />
                    </li>
                  </ul>
                </div>
              </div>

              <div class="sales-btn-area">
                <div v-if="$store.getters.isExist(singleProct.id)" class="counter-btn">
                  <button class="btn" @click="deductQty(singleProct)">-</button>

                  <button class="value-btn" readonly>
                    {{ $store.getters.prodQty(singleProct.id) }}
                  </button>

                  <button class="btn" :disabled="singleProct.product_price[0].quantity ===
                    $store.getters.prodQty(singleProct.id)
                    " @click="addQty">
                    +
                  </button>
                </div>
              </div>
              <div class="stock">
                <p v-if="singleProct.hasOwnProperty('product_price')" class="stock-st">
                  <b><span>Stock Status:</span>
                    {{ singleProct.product_price[0].quantity }} available only</b>
                </p>
              </div>
            </div>
          </div>
          <!--
          <div class="col-md-6">
              <div v-if="Object.keys(singleProct).length !== 0 " class="cart-btn">
                  <button v-if="!$store.getters.isExist(singleProct.id) && singleProct.product_price[0].quantity>0" @click="buyNow(singleProct)" class="btn btn-primary buy-now">Buy Now</button>
              </div>
          </div>
          <div class="col-md-6">
              <div v-if="Object.keys(singleProct).length !== 0 " class="cart-btn">
                  <button v-if="!$store.getters.isExist(singleProct.id) && singleProct.product_price[0].quantity>0" @click="addToBag(singleProct)"  class="btn btn-primary add-bag">Add to bag</button>
              </div>
          </div>
          -->
          <div v-if="!$store.getters.isExist(singleProct.id)" class="col-md-6">
            <div class="cart-btn">
              <!--                          {{!$store.getters.isExist(singleProct.id)}}/{{singleProct.product_price[0].quantity>0}}-->
              <button @click="buyNow()" class="btn btn-primary buy-now">Buy Now</button>
            </div>
          </div>
          <div v-if="!$store.getters.isExist(singleProct.id)" class="col-md-6">
            <div class="cart-btn">
              <button @click="addToBag()" class="btn btn-primary add-bag">
                Add to bag
              </button>
            </div>
          </div>
          <div v-if="Object.keys(singleProct).length !== 0" class="col-md-6">
            <div v-if="
              !$store.getters.isExist(singleProct.id) &&
              singleProct.product_price[0].quantity <= 0
            " class="product_action">
              <button class="plus product-add-btn notify" @click="notifyMe(singleProct)">
                Sold Out. Notify Me
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header eu-modal-header">
                  <h5 class="modal-title eu-modal-title" style="color: #fff">
                    {{ notifyMeData.name }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="font-weight: 100">
                    <span aria-hidden="true" @click="showModal = false">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group" style="margin-bottom: 1rem">
                    <input type="text" class="form-control" v-model="name" placeholder="Your Name" />
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" v-model="mobileNo" placeholder="Your Mobile Number" />
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="showModal = false">
                    Close
                  </button>
                  <button type="button" class="btn btn-primary"
                    @click="notifyMeForSubmit(name, mobileNo, notifyMeData)">
                    Notify Me
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="col-md-12">
      <div class="description-tab-area">
        <b-tabs content-class="mt-3">
          <b-tab title="Description" active>
            <div class="v-description" v-html="description"></div>
          </b-tab>
          <b-tab title="Return Policy">
            <p></p>
          </b-tab>
          <b-tab title="Reviews">
            <p></p>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <RelatedProduct v-if="productDetails.releted_products.length > 0"
      :products="productDetails.releted_products[0].products" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import "../../assets/group_product.css";
import RelatedProduct from "../Home/RelatedProduct";

export default {
  name: "GroupProductDetails",
  props: [],
  data() {
    return {
      inImage: null,
      singleProductImg: null,
      showPriceRange: true,
      productName: "",
      group_product_id: "",
      brandName: "",
      origin: "",
      slug: "",
      description: "",
      price: "",
      sku: "",
      deliveryCharge: "",
      colors: [],
      sizes: [],
      singleProct: {},
      selectedClrId: 0,
      isSelected: false,
      showModal: false,
      notifyMeData: "",
      mobileNo: "",
      name: "",
      img: "",
    };
  },
  metaInfo() {
    return {
      title: this.productName,
      titleTemplate: "%s | Eurasia",
      meta: [
        {
          name: "description",
          content: this.productDetails.meta_description,
        },
        {
          name: "keyword",
          content: this.productDetails.meta_keyword,
        },
      ],
    };
  },
  watch: {
    productDetails() {
      this.productName = this.productDetails.name;
    },
  },
  components: {
    "inner-image-zoom": InnerImageZoom,
    RelatedProduct,
  },
  computed: {
    ...mapGetters([
      "productDetails",
      "shoppingBag",
      "productColor",
      "productSize",
      "accessToken",
    ]),
  },
  mounted() {
    this.productName = this.productDetails.name;
    this.setProductInfo();
  },
  methods: {
    asignImage(img) {
      this.inImage = img;
    },
    setProductInfo() {
      this.productName = this.productDetails.name;
      this.brandName = this.productDetails.brand.name;
      this.origin = this.productDetails.groupProducts[0].product.country_of_manufacture_id.name;
      this.slug = this.productDetails.brand.slug;
      this.description = this.productDetails.short_description;
      this.deliveryCharge = this.productDetails.flat_rate_value;
      this.colors = this.productColor;
      this.sizes = this.productSize;
      this.sku = this.productDetails.sku;
      // console.log(this.productDetails);
    },
    productByColor(product, sId) {
      // console.log("click===", product, sId);
      this.showPriceRange = false;
      this.productName = product.product.name;
      this.brandName = product.product.brand.name;
      this.origin = product.product.country_of_manufacture_id.name;
      this.slug = product.product.brand.slug;
      this.description = product.product.short_description;
      this.deliveryCharge = product.product.flat_rate_value;
      this.price = product.product.product_price[0].price;
      this.singleProct = product.product;
      this.sku = product.product.sku;
      this.group_product_id = product.group_product_id;
      this.isSelected = true;
      this.selectedClrId = sId;
      this.singleProductImg = product.product.product_images;
      this.inImage = product.product.product_images.filter(
        (img) => img.web_id === 2
      )[0].large_image;
      this.$store.dispatch("setZoomimage", product.product.product_images);
      // console.log(product.product);
    },
    /*productBySize(product, sId) {
        // console.log("=============pro zize", product);
        this.showPriceRange = false;
        this.productName = product.product.name;
        this.brandName = product.product.brand.name;
        this.origin = product.product.country_of_manufacture_id.name;
        this.slug = product.product.brand.slug;
        this.description = product.product.short_description;
        this.deliveryCharge = product.product.flat_rate_value;
        this.price = product.product.product_price[0].price;
        this.singleProct = product.product;
        this.sku = product.product.sku;
        this.group_product_id = product.group_product_id;
        this.isSelected = true;
        this.selectedClrId = sId;
        this.$store.dispatch("setZoomimage", product.product.product_images);
        // console.log(product.product);
        // console.log(product);
    },*/
    addToBag() {
      // console.log("====", this.singleProct);
      if (this.isSelected === false) {
        this.$swal.fire({
          icon: "error",
          title: "Please select color or size.",
          confirmButtonColor: "#f60000",
        });
        //alert("Please select color/size");
      } else {
        if (this.singleProct.product_price[0].quantity > 0) {
          this.$store.dispatch("sohwCart");
          this.singleProct.qty = 1;
          this.singleProct.group_product_id = this.group_product_id;
          this.singleProct.cartImage = this.singleProct.product_images.filter(
            (img) => img.web_id === 2
          )[0].thumb_image;
          // this.singleProct.product_price[0].offer_price > 0
          //   ? (this.singleProct.unitPrice =
          //     this.singleProct.product_price[0].offer_price)
          //   : (this.singleProct.unitPrice =
          //     this.singleProct.product_price[0].price);
          if (this.$store.getters.preCountGroupProduct(this.group_product_id) > 0) {
            // console.log("00");
            let price = 0;
            let lastQty =
              this.$store.getters.preCountGroupProduct(this.group_product_id) + 1;

            if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_three &&
              this.singleProct.product_price[0].tier_quantity_three !== 0
            ) {
              // console.log("11");
              price = this.singleProct.product_price[0].tier_price_three;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_two &&
              this.singleProct.product_price[0].tier_quantity_two !== 0 &&
              lastQty < this.singleProct.product_price[0].tier_quantity_three
            ) {
              // console.log("22");
              price = this.singleProct.product_price[0].tier_price_two;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_two &&
              this.singleProct.product_price[0].tier_quantity_two !== 0 &&
              this.singleProct.product_price[0].tier_price_three === 0
            ) {
              // console.log("33");
              price = this.singleProct.product_price[0].tier_price_two;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
              // this.shoppingBag.forEach((item) => {
              //   if (item.group_product_id === this.group_product_id) {
              //     item.unitPrice= price;
              //     item.amount= item.qty * price;
              //   }
              // });
            } else if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_one &&
              this.singleProct.product_price[0].tier_quantity_one !== 0 &&
              lastQty < this.singleProct.product_price[0].tier_quantity_two
            ) {
              // console.log("44");
              price = this.singleProct.product_price[0].tier_price_one;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_one &&
              this.singleProct.product_price[0].tier_quantity_one !== 0 &&
              this.singleProct.product_price[0].tier_price_two === 0
            ) {
              // console.log("55");
              price = this.singleProct.product_price[0].tier_price_one;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else if (this.singleProct.product_price[0].offer_price > 0) {
              // console.log("66");
              price = this.singleProct.product_price[0].offer_price;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else {
              // console.log("77");
              price = this.singleProct.product_price[0].price;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            }

            this.singleProct.unitPrice = price;
          } else {
            this.singleProct.unitPrice =
              this.singleProct.product_price[0].offer_price > 0
                ? this.singleProct.product_price[0].offer_price
                : this.singleProct.product_price[0].price;
          }

          this.$store.dispatch("addToBag", this.singleProct);
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Out of stock.",
            confirmButtonColor: "#f60000",
          });
        }
      }
      // console.log("product from component", this.singleProct);
    },
    buyNow() {
      if (this.isSelected === false) {
        this.$swal.fire({
          icon: "error",
          title: "Please select color or size.",
          confirmButtonColor: "#f60000",
        });
      } else {
        if (this.singleProct.product_price[0].quantity > 0) {
          this.$store.dispatch("sohwCart");
          this.singleProct.qty = 1;
          this.singleProct.group_product_id = this.group_product_id;
          this.singleProct.cartImage = this.singleProct.product_images.filter(
            (img) => img.web_id === 2
          )[0].thumb_image;
          // this.singleProct.product_price[0].offer_price > 0
          //   ? (this.singleProct.unitPrice =
          //     this.singleProct.product_price[0].offer_price)
          //   : (this.singleProct.unitPrice =
          //     this.singleProct.product_price[0].price);

          if (this.$store.getters.preCountGroupProduct(this.group_product_id) > 0) {
            // console.log("00");
            let price = 0;
            let lastQty =
              this.$store.getters.preCountGroupProduct(this.group_product_id) + 1;

            if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_three &&
              this.singleProct.product_price[0].tier_quantity_three !== 0
            ) {
              // console.log("11");
              price = this.singleProct.product_price[0].tier_price_three;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_two &&
              this.singleProct.product_price[0].tier_quantity_two !== 0 &&
              lastQty < this.singleProct.product_price[0].tier_quantity_three
            ) {
              // console.log("22");
              price = this.singleProct.product_price[0].tier_price_two;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_two &&
              this.singleProct.product_price[0].tier_quantity_two !== 0 &&
              this.singleProct.product_price[0].tier_price_three === 0
            ) {
              // console.log("33");
              price = this.singleProct.product_price[0].tier_price_two;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
              // this.shoppingBag.forEach((item) => {
              //   if (item.group_product_id === this.group_product_id) {
              //     item.unitPrice= price;
              //     item.amount= item.qty * price;
              //   }
              // });
            } else if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_one &&
              this.singleProct.product_price[0].tier_quantity_one !== 0 &&
              lastQty < this.singleProct.product_price[0].tier_quantity_two
            ) {
              // console.log("44");
              price = this.singleProct.product_price[0].tier_price_one;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else if (
              lastQty >= this.singleProct.product_price[0].tier_quantity_one &&
              this.singleProct.product_price[0].tier_quantity_one !== 0 &&
              this.singleProct.product_price[0].tier_price_two === 0
            ) {
              // console.log("55");
              price = this.singleProct.product_price[0].tier_price_one;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else if (this.singleProct.product_price[0].offer_price > 0) {
              // console.log("66");
              price = this.singleProct.product_price[0].offer_price;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            } else {
              // console.log("77");
              price = this.singleProct.product_price[0].price;
              this.$store.getters.updateGroupProduct(this.group_product_id, price);
            }

            this.singleProct.unitPrice = price;
          } else {
            this.singleProct.unitPrice =
              this.singleProct.product_price[0].offer_price > 0
                ? this.singleProct.product_price[0].offer_price
                : this.singleProct.product_price[0].price;
          }

          this.$store.dispatch("addToBag", this.singleProct);
          if (this.accessToken) {
            this.$router.push("/checkout").catch(() => { });
          } else {
            this.$router.push({ name: "checkout" }).catch(() => { });
          }
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Out of stock.",
            confirmButtonColor: "#f60000",
          });
        }
      }
      // console.log("", this.singleProct);
    },
    addQty() {
      // // console.log('update', product.product_price[0].tier_quantity_three)
      this.singleProct.product_price[0].offer_price > 0
        ? (this.singleProct.unitPrice = this.singleProct.product_price[0].offer_price)
        : (this.singleProct.unitPrice = this.singleProct.product_price[0].price);
      this.$store.dispatch("addQty", this.singleProct);
    },
    deductQty(product) {
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) == 0) {
        this.$store.dispatch("removeFromCart", product);
      }
    },
    notifyMe(data) {
      this.notifyMeData = data;
      this.showModal = true;
    },
    notifyMeForSubmit(name, phone, product) {
      if (name !== "" && phone !== "" && product.id) {
        let notifyDataForPass = {
          name: name,
          phone: phone,
          product_id: this.notifyMeData.id,
        };
        // console.log("notify", notifyDataForPass);
        this.$store.dispatch("soldOutNotifyMe", notifyDataForPass).then((res) => {
          // console.log("Response Data = ", res);
          if (res === undefined) {
            window.alert("Successfully Submitted.");
            this.mobileNo = "";
            this.name = "";
          }
        });
        // console.log("Data for pass: ", notifyDataForPass);
      }
      this.showModal = false;
    },
  },
};
</script>
<!--
<style>
    .product-details {
        text-align: left;
    }
    .product-desc h4 {
        margin-bottom: 5px;
    }
    b.brand-name {
        text-transform: uppercase;
        font-weight: 500;
    }
    .price {
        font-size: 1.3em;
    }
    span.price-tag {
        font-size: 1.25em;
        color: #990000;
    }
    .counter-btn button {
        border: 1px solid #ddd;
        font-weight: 900;
        font-size: 22px;
        padding: 0px 10px;
        text-align: center;
        color: #aaa;
    }
    button.value-btn {
        font-size: 15px;
        font-weight: 100;
        padding: 9px 9px;
        line-height: 1;
        color: #000;
    }
    .cart-btn{
        text-align: left;
        margin-top: 20px;
    }
    .cart-btn .btn {
        border-radius: 50px;
        width: 80%;
        font-size: 15px;
        padding: 7px 0px;
    }
    button.btn.btn-primary.buy-now {
        background: red;
        color: #fff;
        text-transform: capitalize;
    }
    button.btn.btn-primary.buy-now:hover{
        background: #000;
        border-color: #000;
    }
    button.btn.btn-primary.add-bag {
        background: #000;
        border-color: #000;
        color: #fff;
        text-transform: capitalize;
    }
    button.btn.btn-primary.add-bag:hover{
        background: red;
        border-color: red;
    }
    .notify {
        padding: 10px 15px !important;
        font-size: 14px;
    }
    li.thumb-img {
        width: 100px;
        border: 1px solid #ddd;
        margin-bottom: 5px;
        border-radius: 3px;
    }
    li.thumb-img img {
        width: 100px;
        padding: 10px;
    }
    li.thumb-img:hover {
        cursor: pointer;
    }
    .counter-btn {
        margin-top: 30px;
    }
    b.brand-name a {
        color: #4F8ECF;
    }
    p.dsc {
        margin-top: 20px;
        font-size: 1.1em;
    }
    p.sku {
        margin: 20px 0px;
    }
    figure.iiz.large-img-sz {
        height: 450px;
    }
    .iiz__img {
        max-width: 100%;
        height: 450px !important;
        display: block;
        pointer-events: none;
        visibility: visible;
        opacity: 1;
    }
    p.stock-st {
        margin: 20px 0px;
        color: red;
    }
    p.stock-st span{
        color: #35b035;
    }
    .col-md-4.wrap-border {
        border-right: 1px solid #ddd;
        margin-bottom: 20px;
    }
    .product-color {
        display: flex;
    }
    .color-name p {
        margin: 20px 0px;
    }
    .color-shade {
        margin: 10px 15px;
    }
/*    .color-shade ul {
        display: flex;
    }*/
    .color-shade ul li {
        margin: 0px 5px;
      float: left;
    }
    .color-shade ul li:hover{
        cursor: pointer;
    }
    .color-shade ul>li img {
        height: 40px !important;
        width: 40px !important;
        border-radius: 7px !important;
        padding: 2px;
        border: 1px solid #ddd;
    }
    @media (max-width: 959px) {
      .thumbnail-img ul li{
        display: inline-block;
      }
        li.thumb-img {
            width: 52px !important;
        }
        li.thumb-img img {
            width: 52px !important;
            padding: 0px !important;
        }
        /*figure.iiz.large-img-sz {
            height: 220px !important;
        }
        img.iiz__img {
            height: 220px !important;
        }*/
        .iiz__btn {
            display: none !important;
        }
      .description-tab-area ul li a {
        color: #000;
        font-weight: 600;
        font-size: 13px;
        padding: 8px 10px;
      }
    }
</style>-->
